import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Notification } from "hds-react";
import ExternalLink from '../../../../components/ExternalLink';
import Playground from '../../../../components/Playground';
import TextExample from '../../../../components/examples/TextExample';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/foundation/design-tokens/typography/tokens",
  "title": "Typography tokens - Tokens"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2 {...{
      "id": "tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Tokens`}<a parentName="h2" {...{
        "href": "#tokens",
        "aria-label": "tokens permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <Notification label="Consider using helper classes" className="siteNotification" mdxType="Notification">
  Instead of using tokens directly to style your typography, consider using <ExternalLink size="S" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/utils/helpers.css" mdxType="ExternalLink">helper classes provided in the Core package</ExternalLink>. In these classes, you do not need to worry about matching the correct font weight, line height and letter spacing are matched to the chosen font size.
    </Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--font-default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$font-default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HelsinkiGrotesk`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Font tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`REM value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-heading-xxl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-heading-xxl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-heading-xxl)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-heading-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-heading-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-heading-xl)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-heading-xl-mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-heading-xl-mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.5rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-heading-xl-mobile)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-heading-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-heading-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-heading-l)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-heading-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-heading-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.5rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-heading-m)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-heading-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-heading-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.25rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-heading-s)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-heading-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-heading-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.125rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-heading-xs)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-heading-xxs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-heading-xxs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-heading-xxs)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-body-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-body-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.25rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-body-xl)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-body-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-body-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.125rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-body-l)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-body-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-body-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-body-m)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-body-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$fontsize-body-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.875rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><TextExample size="var(--fontsize-body-s)" mdxType="TextExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Font size tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value (ratio)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--lineheight-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$lineheight-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--lineheight-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$lineheight-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--lineheight-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$lineheight-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--lineheight-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$lineheight-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.75`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Line height tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      